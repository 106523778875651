<template>
  <sign-page
    title-text="职称聘任记录"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :title-menus="titleMenus"
    :tableActions="tableActions"
    :dealFormData="dealFormData"
    :form-parms-add="formParmsAdd"
    :tableActionsFixed="true"
    :form-parms-update="formParmsUpdate"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerJobTitleRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (this.$authFunsProxy.edit || this.$authFunsProxy.add) {
      this.$store.dispatch('loadWorkerList')
      this.$store.dispatch('loadJobTitleList')
    }
  },
  computed: {
    jobTitleAllWorker () {
      return this.$store.getters.sysConfig['jobTitleAllWorker']
    },
    tableActions () {
      return [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    titleMenus () {
      return [{key: 'add', label: '新增'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.jobTitleAllWorker ? this.$store.getters.workerList : this.$store.getters.workerList.filter(v => v.data.type === '在编'),
          label: '职工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['jobtitle_type_list'],
          label: '岗位专业',
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.jobTitleList,
          label: '岗位',
          key: 'jobTitleId',
          check: {
            required: true
          }
        },
        // {
        //   type: 'datePicker',
        //   label: '上岗时间',
        //   key: 'startDate',
        //   check: {
        //     required: true
        //   }
        // },
        // {
        //   type: 'datePicker',
        //   label: '离任时间',
        //   key: 'endDate'
        // },
        {
          type: 'datePicker',
          label: '聘任起始时间',
          key: 'hireStartDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '聘任终止时间',
          key: 'hireEndDate'
        },
        {
          type: 'input',
          label: '在岗单位',
          key: 'hisName'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '员工编号',
          sort: true,
          field: 'workerCode'
        },
        {
          title: '职工',
          sort: true,
          field: 'workerName'
        },
        {
          title: '岗位专业',
          sort: true,
          field: 'type',
          filters: this.$store.getters.selectDatas['jobtitle_type_list'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位类型',
          sort: true,
          field: 'jobTitleType',
          filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位层级',
          sort: true,
          field: 'topLevel',
          filters: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位等级',
          sort: true,
          field: 'jobTitleTitle',
          filterMethod (parm) {
            return parm.row.jobTitleTitle === parm.value
          }
        },
        // {
        //   title: '在岗时间段',
        //   sort: true,
        //   field: 'zgsjd',
        //   width: 200
        // },
        {
          title: '聘任起始时间',
          sort: true,
          field: 'hireStartDate',
          width: 200,
          filterRange: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
          }
        },
        {
          title: '聘任终止时间',
          sort: true,
          field: 'hireEndDate',
          filterRange: true,
          width: 200,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '至今')
          },
          text: (rowData) => {
            return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '至今'
          }
        },
        {
          title: '在岗单位',
          sort: true,
          field: 'hisName'
        },
        {
          title: '累计聘任上岗年限',
          sort: true,
          field: 'years',
          dataType: Number,
          filterRange: true
        }]
        return data
      }
    }
  },
  methods: {
    dealFormData (data) {
      // if (data.startDate && data.endDate && this.timeBj(data.startDate, data.endDate)) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '离任时间需要大于上岗时间。'
      //   })
      //   throw Error()
      // }
      if (data.hireStartDate && data.hireEndDate && this.timeBj(data.hireStartDate, data.hireEndDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '聘任终止时间需要大于聘任起始时间。'
        })
        throw Error()
      }
      return data
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    async loadData () {
      let data = await request.get()
      data.sort((a, b) => a.workerCode - b.workerCode)
      data.forEach((v) => {
        let startY = v.hireStartDate ? Number(v.hireStartDate.slice(0, 4)) : null
        let endY = v.hireEndDate ? Number(v.hireEndDate.slice(0, 4)) : new Date().getFullYear()
        v.years = startY ? endY - startY + 1 : null
        // v.zgsjd = ''
        // if (v.startDate) {
        //   v.zgsjd += v.startDate ? v.startDate.slice(0, 10) : ''
        // }
        // v.zgsjd += v.endDate ? ('至' + v.endDate.slice(0, 10)) : '至今'
      })
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
